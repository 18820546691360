.Footer {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding: 25px 53px;
}

.paragraphText .paragraph {
  width: 444px;
  text-align: left;
  color:#474444
}

.contact .value {
  color:#474444
}