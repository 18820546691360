.label {

}

.form.input {
  margin-top: 1rem;
}

.form.input.name {
  width: 30%;
}

.form.input::placeholder {
  color: #D4D2D2;
  font-family: "Open Sans", sans-serif;
}

.form.submit.button {
  cursor: pointer;
}

.form.input.message {
  font-family: "Open Sans", sans-serif;
}