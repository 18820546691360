.homePage {
  font-family: 'Open Sans', sans-serif;
  color: #504D4D;
}

.homePage.paragraphText {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  line-height: 22px;
  font-weight: 400;
}

.header:last-of-type {
  margin-top: 10px;
}