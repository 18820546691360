.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 25px;
}

.navbar.navLinks.flexContainer {
  display: flex;
  width: 60%;
}

.navLink {
  font-weight: 700;
  font-size: 13px;
  height: 16px;
  width: fit-content;
  color: #504d4d;
  text-decoration: none;
  letter-spacing: 0.25em;
  padding-left: 0.25em;
  text-align: center;
  vertical-align: middle;
  font-family: 'Roboto', sans-serif;
}

.navLink.active {
  border-bottom: 1px solid #504d4d;
}